export const environment = {
  production: true,
  apiUrl: 'https://admin.cte.mcu.es/api/v1/',
  baseUrl: 'https://admin.cte.mcu.es/',
  apiKeyMap: 'AIzaSyBMDhDxtsJolCrpTo5EsLtojSomOzVhqmo',
  paymentsUrl: 'http://walleq.io/',
  auth: {
    clientSecret: 'xGbAhWL960HycCoboFNvKdhpWJxnLdxIs55Gjn95',
    clientId: '2'
  }
};

